.menu-button {
    width: 18px;
    height: 18px;
    background-image: url(../img/burger.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    display: none;
}

.mobile-menu-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #B8DFE4;
    left: 0;
    top: 0;
    z-index: 10;
    left: -100%;
    transition: .3s;
}
.mobile-menu-wrapper.show {
    left: 0;
}
.mobile-menu-wrapper .menu {
    font-family: 'CrashCTT';
    padding: 0;
    padding-top: 70px;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0;
    font-size: 30px;
    list-style-type: none;
}
.mobile-menu-wrapper .menu li a{
    text-decoration: none;
}
.close-menu {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 16px;
    height: 16px;
    background-image: url(../img/close.svg);
    background-size: 16px 16px;
    cursor: pointer;
}
@media (max-width: 1200px) {
    body {
        font-size: 16px;
    }
    .container {
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }

    
    .main-block .container {
        grid-template-columns: 1fr;
    }
    .main-block-slider-container {
        width: 100%;
        margin: 0 auto;
    }
    .main-block-content {
        width: 80%;
        margin: 0 auto;
    }
    .main-block-slider-item-pic {
        // height: 400px;
        height: auto;
        position: relative;
    }
    .main-block-slider-item::before {
        padding-top: 60%;
        content: '';
        display: block;
    }
    .main-block-slider-item-pic {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .last-news {
        padding: 26px;
        margin-bottom: 50px;
    }
    .last-news-top {
        margin-bottom: 20px;
    }
    .last-news-list {
        gap: 50px;
    }
    .video-section-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .video-section::before {
        padding-top: 60%;
        content: '';
        display: block;
    }
    .search-filter {
        padding: 24px;
    }
    .filter-field {
        width: 100%;
    }
    .filter-field select {
        font-size: 18px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
        min-width: 60px;
    }
    .filter-field input {
        font-size: 18px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
        min-width: 60px;
    }

    .page-title {
        font-size: 24px;
    }
    .articles-item-title {
        font-size: 20px;
        font-weight: bold;
    }
    .filter-result-list {
        column-count: 1;
    }
    .block-list {
        column-count: 2;
    }
    .filter-links {
        font-size: 24px;
    }
    .block-title {
        font-size: 20px;
    }
    .filter-result-item {
        font-size: 18px;
    }
    .filter-field-name {
        font-size: 18px;
    }

    .film-detail-sidebar {
        width: 300px;
    }
    .film-detail-cols {
        gap: 40px;
    }
    
    .person-info-pic {
        width: 200px;
    }
    .person-info {
        padding: 24px 24px
    }
    .tab-list {

    }
    .tab-item {
        font-size: 16px;
    }
    .tab-content-item {
        padding: 24px;
    }
}

@media (max-width: 768px) {
    .tab-content {
        margin-top: 30px;
    }
    .tab-list {
        display: flex;
        flex-direction: column;
    }
    .tab-item {
        padding-left: 16px;
        padding-right: 16px;

    }
    .tab-item.active::after {
        display: none;
    }
    .tab-content-item {
        
    }


    .persons-section .slider-arrows {
        width: 100%;
    }
    .page-slider-section .slider-arrows {
        width: 100%;
    }
    .search-filter {
        padding: 16px;
    }
    .search-filter form {
        gap: 10px;
    }
    .last-news-item {
        flex-direction: column;
    }
    .last-news-top {
        text-align: right;
    }
    .last-news-item-title {
        font-size: 16px;
    }

    .video-section-title {
        font-size: 18px;
        margin-bottom: 16px;
    }
    .video-section-time {
        font-size: 18px;
    }
    .video-section-play {
        width: 50px;
        height: 50px;
        margin-bottom: 16px;
    }
    .video-section-play img {
        width: 100%;
    }
    .main-footer {
        padding: 34px 20px;
    }
    .main-footer-col:nth-child(1) {
        display: none;
    }
    .main-footer-row {
        display: flex;
        flex-direction: column;
        // align-items: center;
        gap: 20px;
    }
    .main-footer-col:nth-child(3) {
        width: 100%;
    }
    .main-footer-row .main-footer-text {
        width: 100%;
        text-align: right;
    }
    .main-footer-text {
        width: 100%;
        text-align: left;
    }

    .film-detail-cols {
        flex-direction: column;
        
    }
    .film-detail-sidebar {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }


    .person-info-cols {
        flex-direction: column;   
    }
}